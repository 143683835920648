import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  BUSINESS_ADD_FAILED,
  BUSINESS_ADD_PENDING,
  BUSINESS_ADD_SUCCESS,
  BUSINESS_READY,
  BUSINESS_UPDATE_FAILED,
  BUSINESS_UPDATE_PENDING,
} from "./businessStatus";

import FirestoreBusiness from "../../api/firebase/firestore.business";
const businessAdapter = createEntityAdapter({
  selectId: (business) => business.uid,
});

const initialState = businessAdapter.getInitialState({
  status: BUSINESS_READY,
});

export const businessAdd = createAsyncThunk(
  "business/Add",
  async (businessData) => {
    const business = await FirestoreBusiness.Create(businessData);
    return business;
  }
);

export const businessSelected = createAsyncThunk(
  "business/selected",
  async (businessData) => {
    const business = FirestoreBusiness.Selected(businessData);
    return business;
  }
);

export const businessUpdate = createAsyncThunk(
  "business/update",
  async (businessData) => {
    const business = await FirestoreBusiness.Update(businessData);
    return business;
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    businessStatus(state, action) {
      state.status = action.payload;
    },
    businessAddMany: businessAdapter.addMany,
    businessAddOne: businessAdapter.addOne,
    businessUpdateOne: businessAdapter.updateOne,
    busiessSelected(state, action) {
      const businessId = action.payload;
      Object.values(state.entities).forEach((business) => {
        business.selected = business.uid === businessId ? true : false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(businessAdd.rejected, (state) => {
        state.status = BUSINESS_ADD_FAILED;
      })
      .addCase(businessAdd.pending, (state) => {
        state.status = BUSINESS_ADD_PENDING;
      })
      .addCase(businessAdd.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.status = BUSINESS_ADD_SUCCESS;
          businessAdapter.addOne(state, action.payload);
        } else {
          state.status = BUSINESS_ADD_FAILED;
        }
      })
      .addCase(businessSelected.rejected, (state) => {
        state.status = BUSINESS_UPDATE_FAILED;
      })
      .addCase(businessSelected.pending, (state) => {
        state.status = BUSINESS_UPDATE_PENDING;
      })
      .addCase(businessSelected.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.status = BUSINESS_READY;
          businessAdapter.updateMany(state, action.payload);
        } else {
          state.status = BUSINESS_UPDATE_FAILED;
        }
      })
      .addCase(businessUpdate.rejected, (state) => {
        state.status = BUSINESS_UPDATE_FAILED;
      })
      .addCase(businessUpdate.pending, (state) => {
        state.status = BUSINESS_UPDATE_PENDING;
      })
      .addCase(businessUpdate.fulfilled, (state, action) => {
        state.status = BUSINESS_READY;
        if (action.payload && action.payload.uid) {
          businessAdapter.updateOne(state, {
            id: action.payload.uid,
            changes: action.payload, 
          });
        }
      });
      
  },
});

export default businessSlice.reducer;

export const {
  businessAddMany,
  businessAddOne,
  businessUpdateOne,
  businessStatus,
} = businessSlice.actions;

export const { selectAll: selectBusinesses, selectById: selectBusinessById } =
  businessAdapter.getSelectors((state) => state.businesses);

export const selectBusinessIds = createSelector(
  selectBusinesses,
  (businesses) => businesses.map((business) => business.uid)
);

export const selectBusinessSelected = createSelector(
  selectBusinesses,
  (businesses) => {
    if (businesses.length === 1) {
      return businesses[0];
    }
    return businesses.filter((business) => business.selected === true)[0];
  }
);

import React from "react";
import OnBoardingLogoHeader from "../../components/header/OnBoardingLogoHeader";
import Svg from "../../assets/svg";
import { useNavigate } from "react-router-dom";
import { SPLASH } from "../../router/routeNames";

const WalletSetupSuccessfully = () => {
  const navigate = useNavigate();
  const handleExplorer = () => {
    navigate(SPLASH, { replace: true });
  };

  return (
    <div className="flex-1 min-h-screen flex flex-col bg-white">
      <OnBoardingLogoHeader />
      <div className="self-start w-4/5 h-1.5 bg-indigo-700 mb-6" />
      <div className="flex-1 flex items-center justify-center px-6 lg:px-16">
        <div className="flex flex-col items-center w-full p-8 mt-[20px]">
          <div className="w-full flex justify-center mb-6">
            <img
              src={Svg.wallet_setup_done}
              alt="Wallet"
              className="object-contain max-w-full h-32 md:h-40 lg:h-48"
            />
          </div>
          <h1 className="text-center text-gray-900 text-2xl lg:text-3xl font-bold leading-[1.2]">
            You are all set!
          </h1>
          <p className="text-center text-gray-500 text-lg font-medium leading-normal tracking-tight mt-4">
            Thank you for your patience. You can now sign in to your account.
          </p>
          <button
            onClick={handleExplorer}
            className="w-full max-w-sm h-14 mt-8 p-2.5 bg-indigo-700 rounded-xl flex justify-center items-center gap-2.5 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="text-center text-white text-lg font-bold leading-normal tracking-tight">
              Explore
            </span>
          </button>
        </div>
      </div>
      <div className="w-full h-24 flex items-end justify-center mt-6 mb-4 text-center">
        <div className="text-gray-500 text-xs font-medium">
          © 2024 JLRecharge, LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default WalletSetupSuccessfully;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import { isValidEmail } from "../../utils/regexHelper";
import Svg from "../../assets/svg";
import LoaderStats from "../../components/loader/LoaderStats";
import { get } from "lodash";
import CopyrightText from "../../components/atoms/CopyrightText";
const EmailResentPasswordSent = () => {
  const authentication = useSelector((state) => state.authentication);
  const authenticationData = get(authentication, "data", {});
  const [loading, setLoading] = useState(false);

  const sendLinkResetPassword = async () => {
    if (!isValidEmail(authenticationData?.email)) {
      return;
    }

    setLoading(true);
    try {
      await FirebaseAuth.SendResetPasswordEmailLink(authenticationData?.email);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white px-4 sm:px-6 lg:px-8">
      <div className="mb-6 flex justify-center mt-[65px]">
        <img
          src={Svg.envelop}
          alt="Email Icon"
          className="object-contain w-24 sm:w-32"
        />
      </div>

      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="max-w-[425px] mb-5 text-gray-900 text-2xl sm:text-3xl lg:text-4xl font-bold leading-tight">
          Check your email
        </h1>

        <p className="max-w-[570px] mb-10 text-gray-500 text-base sm:text-lg font-medium leading-normal">
          We have sent you an email with a password reset link. Please follow
          the instructions to reset your password.
        </p>

        <a
          href="mailto:"
          className="w-full sm:w-[436px] h-[52px] sm:h-[60px] mb-4 p-2.5 bg-indigo-700 rounded-xl flex justify-center items-center gap-2.5 text-white text-sm sm:text-base lg:text-lg font-bold leading-normal tracking-tight focus:outline-none hover:bg-indigo-800 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Open Email
        </a>

        <div className="w-full sm:w-[469px] mb-6 text-center text-gray-500 text-sm sm:text-base lg:text-lg font-medium leading-normal tracking-tight">
          Didn’t get the email? Check your spam folder.
        </div>

        <div className="text-center text-gray-500 text-sm sm:text-base lg:text-lg font-medium leading-normal mb-6">
          Or
        </div>

        <button
          type="button"
          onClick={sendLinkResetPassword}
          className={`w-full sm:w-[436px] h-[52px] sm:h-[60px] p-2.5 rounded-xl border border-gray-200 flex justify-center items-center gap-2.5 focus:outline-none 
        ${
          loading
            ? "cursor-not-allowed opacity-70"
            : "hover:bg-indigo-50 hover:border-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        }`}
          disabled={loading}
        >
          {loading ? (
            <LoaderStats />
          ) : (
            <div className="text-indigo-700 text-sm sm:text-base lg:text-lg font-bold leading-normal tracking-tight hover:text-indigo-800">
              Resend email
            </div>
          )}
        </button>
      </div>
      <div className="absolute bottom-[0px] w-full">
        <CopyrightText />
      </div>
    </div>
  );
};

export default EmailResentPasswordSent;

import logo from "./logo.svg";
import envelop from "./envelop.svg";
import email_verified from "./email_verified.svg";
import chevron from "./chevron.svg";
import user_profile from "./user_profile.svg";
import back from "./back.svg";
import user_commission from "./user_commission.svg";
import user_contact_globally from "./user_contact_globally.svg";
import user_operators from "./user_operators.svg";
import wallet from "./wallet.svg";
import wallet_setup_done from "./wallet_setup_done.svg";
const Svg = {
    logo,
    wallet_setup_done,
    wallet,
    envelop,
    email_verified,
    chevron,
    user_profile,
    back,
    user_commission,
    user_contact_globally,
    user_operators
};  
export default Svg;
import React from "react";

const CopyrightText = () => {
  return (
    <div className="text-center py-3 bg-white fixed bottom-0 w-full text-gray-500 text-xs sm:text-sm md:text-base lg:text-base font-medium leading-tight tracking-tight">
      © 2024 JLRecharge, LLC. All rights reserved.
    </div>
  );
};

export default CopyrightText;


import React from "react";
import PropTypes from "prop-types";
const LoaderStats = ({ size = "small", color = "white-600" }) => {
  const sizeClasses = size === "large" ? "h-10 w-10" : "h-6 w-6"; 

  return (
    <div
      className={`animate-spin rounded-full border-4 border-t-transparent border-${color} ${sizeClasses}`}
    />
  );
};

// Add prop types validation
LoaderStats.propTypes = {
  size: PropTypes.oneOf(["small", "large"]), 
  color: PropTypes.string,
};

export default LoaderStats;

export const SPLASH = "/loading";
export const DASHBOARD = "/dashboard";
export const RECHARGE = "/recharge";

// TRANSACTION
export const TRANSACTIONS = "/transactions";
export const TRANSACTION_DETAILS = "/transaction";

// AUTH
export const LOGIN = "/login";
export const SIGNUP = "/sign-up";
export const EMAIL_VERIFIED = "/email-verified";
export const EMAIL_VERIFICATION = "/email-verification";
export const FORGOT_PASSWORD = "/auth/forgotpassword";
export const FORGOT_PASSWORD_OPEN_EMAIL = "/auth/forgotpasswordopenemail";
export const NEW_PASSWORD = "/auth/newpassword";

//USER
export const PROFILE = "/profile";
export const PERSONAL_INFORMATION = "/personal_information";


// BUSINESS
export const BUSINESS = "/business";
export const BUSINESS_INFORMATION = "/business-information";
export const BUSINESS_DETAILS = "/business/details";
export const BUSINESS_ADD = "/business/add";
export const BUSINESS_ADD_AGENT = (businessId) =>
  `/business/${businessId}/agent`;

// WALLET
export const WALLET = "/wallets";
export const WALLET_ADD_PAYMENTMETHOD = "/wallet/add-pyment-method";
export const WALLET_RECHARGE = "/wallets/add-fund";
export const WALLET_PAYMENT_METHOD = "/wallet/payment-method/";
export const WALLET_SETUP = "/wallets_setup";
export const WALLET_SETUP_PAYMENT_METHOD = "/wallet/setup-payment-method";
export const WALLET_SETUP_SUCCESSFULLY = "/wallets_setup_successfully";
export const WALLET_PAYMENT_METHOD_DELETE = (paymentMethodId) =>
  `/wallet/payment-method/${paymentMethodId}/delete`;

// PAYMENTS
export const PAYMENTS = "/payments";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Svg from "../../assets/svg";

const DropDown = ({ options, selectedItem, onSelectItem, placeholder = "Select an option", error }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleItemSelect = (item) => {
    onSelectItem(item);
    setIsDropdownOpen(false);
    setSearchTerm(""); 
  };

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (options.length === 0) {
    return null;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`h-[60px] pl-[18px] pr-[17.50px] py-[18px] bg-gray-100 rounded-[10px] flex justify-between items-center cursor-pointer ${error ? "border border-red-500" : ""}`}
        onClick={toggleDropdown}
      >
        <div className={`text-base font-medium leading-normal tracking-tight ${selectedItem ? "text-gray-900" : "text-gray-400"}`}>
          {selectedItem ? selectedItem?.name : placeholder}
        </div>
        <img src={Svg.chevron} alt="Chevron Icon" className="ml-2 object-cover" />
      </div>

      {isDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
          <input
            type="text"
            placeholder="Search..."
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsDropdownOpen(true)}
          />
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleItemSelect(option)}
              >
                {option.name}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

DropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedItem: PropTypes.any,
  onSelectItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.any,
};

export default DropDown;

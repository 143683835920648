import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Svg from "../../assets/svg";

const BusinessBenefits = () => {
  const user = useSelector((state) => state.user);
  const userData = get(user, "data");
 
  return (
    <div>
      <h1 className="text-gray-900 text-[32px] font-bold leading-10">
        Thank you! {userData?.name}
      </h1>
      <p className="text-gray-500 text-lg font-medium leading-normal tracking-tight">
        Please fill up the form to setup your business profile and get benefits
        of JLRspace platform.
      </p>
      <div className="w-[465px] mt-[37px] mb-[24px] h-[72px] pl-4 pr-6 py-4 bg-[#efebfc] rounded-xl justify-start items-center gap-3 inline-flex">
        <div className="w-10 h-10 justify-center items-center inline-flex">
          <img
            src={Svg.user_commission}
            alt="Commission Icon"
            className="object-contain"
          />
        </div>
        <div className="text-[#4343d6] text-xl font-bold leading-7">
          10 % Commission on each credit you sell
        </div>
      </div>
      <div className="w-[465px] h-[72px] mb-[24px] pl-4 pr-6 py-4 bg-[#efebfc] rounded-xl justify-start items-center gap-3 inline-flex">
        <div className="w-10 h-10 justify-center items-center inline-flex">
          <img
            src={Svg.user_contact_globally}
            alt="Contact Globally"
            className="object-contain"
          />
        </div>
        <div className="text-[#4343d6] text-xl font-bold leading-7">
          10 % Commission on each credit you sell
        </div>
      </div>
      <div className="w-[465px] h-[72px] pl-4 pr-6 py-4 bg-[#efebfc] rounded-xl justify-start items-center gap-3 inline-flex">
        <div className="w-10 h-10 justify-center items-center inline-flex">
          <img
            src={Svg.user_operators}
            alt="Operators"
            className="object-contain"
          />
        </div>
        <div className="text-[#4343d6] text-xl font-bold leading-7">
          2 big operators ALou/Unitel T+
        </div>
      </div>
    </div>
  );
};

export default BusinessBenefits;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FirestoreUser from "../api/firebase/firestore.user";
import { AUTHENTICATION_SUCESS } from "../features/authentication/authState";
import { userAdd, userStatus } from "../features/user/userSlice";
import { USER_READY } from "../features/user/userStatus";
import { get } from "lodash";
import { ROLES } from "../features/authentication/models";
import { DASHBOARD, RECHARGE } from "../router/routeNames";
import { useNavigate } from "react-router-dom";

const Splash = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector((state) => state.authentication);
  const authenticationData = get(authentication, "data", {});

  useEffect(() => {
    async function getttingReady() {
      if (authentication === undefined) return;

      if (authentication.status !== AUTHENTICATION_SUCESS) return;

      const userData = await FirestoreUser.LoadById(authenticationData?.uid);
      if (userData === null) {
        const userData = await FirestoreUser.Update({
          uid: authenticationData?.uid,
          email: authenticationData?.email,
        });

        if (userData !== null) {
          dispatch(userAdd(userData));
          dispatch(userStatus(USER_READY));
        }
      }
      dispatch(userAdd(userData));
      dispatch(userStatus(USER_READY));

      const route =
        userData?.roles[ROLES.manager] || userData?.roles[ROLES.partner]
          ? DASHBOARD
          : RECHARGE;
      navigate(route, { replace: true });
    }

    getttingReady();
  }, []);

  return <div>Loading</div>;
};

export default Splash;

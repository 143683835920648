import React, { useEffect, useState } from "react";
import PhoneInput from "../../components/inputs/PhoneInput";
import LoaderStats from "../../components/loader/LoaderStats";
import { isValidPhoneNumber } from "react-phone-number-input";
import Svg from "../../assets/svg";
import Lottie from "react-lottie-player";
import user_profile from "../../assets/lottie/user_profile.json";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "./userSlice";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { BUSINESS_INFORMATION } from "../../router/routeNames";
import CopyrightText from "../../components/atoms/CopyrightText";

const UserPersonalDetail = () => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userData = get(user, "data", {});

  // Initializing formData with user information
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phone: "",
    submit: ""
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        email: userData.email || "",
        fullName: userData.name || "",
        phone: userData.phone || ""
      });
    }
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ""
    });
  };

  const handlePhoneChange = (phoneNumber) => {
    if (phoneNumber) {
      setFormData({
        ...formData,
        phone: phoneNumber
      });

      if (!isValidPhoneNumber(phoneNumber)) {
        setErrors({
          ...errors,
          phone: "Invalid phone number."
        });
        return;
      }
    }

    setErrors({
      ...errors,
      phone: ""
    });
  };

  const validateForm = () => {
    let validationErrors = {};

    // Email validation
    if (!formData.email) {
      validationErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Invalid email format.";
    }

    // Full name validation
    if (!formData.fullName) {
      validationErrors.fullName = "Full Name is required.";
    } else if (formData.fullName.length < 3) {
      validationErrors.fullName =
        "Full Name must be at least 3 characters long.";
    }

    // Phone number validation
    if (!formData.phone) {
      validationErrors.phone = "Phone Number is required.";
    } else if (!isValidPhoneNumber(formData.phone)) {
      validationErrors.phone = "Invalid phone number.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        setIsLoading(true);
        const userInfo = {
          uid: userData.uid,
          email: formData.email,

          name: formData.fullName,
          phone: formData.phone,
          isActive: true
        };
        dispatch(userUpdate(userInfo));
        navigate(BUSINESS_INFORMATION, { replace: true });
      }
    } catch (error) {
      setErrors({
        ...errors,
        submit: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-1 flex flex-col lg:flex-row">
        {/* Left Side Form */}
        <div className="flex-1 p-4 lg:p-10">
          <div className="mx-auto w-full max-w-sm lg:max-w-md mt-10">
            <div className="flex justify-center">
              <h1 className="text-center text-gray-900 text-[32px] sm:text-[40px] font-bold leading-[52px]">
                Let’s get to know you!
              </h1>
            </div>
            <div className="w-full text-center text-gray-500 text-lg font-medium leading-normal tracking-tight">
              Please add your personal information to continue.
            </div>
            <div className="flex-1">
              <div className="mt-3">
                <form onSubmit={handleSubmit} className="p-6">
                  {/* Email Input */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="block text-black text-lg font-medium leading-normal tracking-tight"
                    >
                      Email*
                    </label>
                    <div className="flex">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={Boolean(userData.email)} // Make email non-editable if userData.email exists
                        className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                          errors.email ? "border-red-500" : "border-gray-200"
                        } ${userData.email ? "bg-gray-200 cursor-not-allowed" : ""}`}
                      />
                    </div>
                    {errors.email && (
                      <p className="mt-1 text-red-600 text-sm">
                        {errors.email}
                      </p>
                    )}
                  </div>

                  {/* Full Name Input */}
                  <div className="mb-6">
                    <label
                      htmlFor="fullName"
                      className="block text-black text-lg font-medium leading-normal tracking-tight"
                    >
                      Your Full Name*
                    </label>
                    <div className="flex">
                      <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        placeholder="Enter your full name"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                          errors.fullName ? "border-red-500" : "border-gray-200"
                        }`}
                      />
                    </div>
                    {errors.fullName && (
                      <p className="mt-1 text-red-600 text-sm">
                        {errors.fullName}
                      </p>
                    )}
                  </div>

                  {/* Phone Number Input */}
                  <div className="mb-6">
                    <PhoneInput
                      onChange={handlePhoneChange}
                      errorMessage={errors.phone}
                    />
                  </div>
                  {errors.submit && (
                    <div className="text-red-600 text-sm">{errors.submit}</div>
                  )}
                  <div className="mt-8">
                    <button
                      type="submit"
                      className="w-full flex justify-center py-4 px-4 rounded-xl text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={isLoading}
                    >
                      {isLoading ? <LoaderStats size="small" /> : "Continue"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side Animation */}
        <div className="flex-1 flex-col hidden lg:flex items-center justify-center max-w-full">
          <div className="flex-1 flex-col lg:flex items-center mt-[100px] max-w-full">
            {formData.fullName ? (
              <Lottie
                animationData={user_profile}
                play
                className="object-contain"
              />
            ) : (
              <img
                src={Svg.user_profile}
                alt="Login Visual"
                className="object-contain mb-[10px]"
              />
            )}
            <div className="w-full max-w-md text-center text-gray-900 text-xl lg:text-2xl font-bold mb-[30px]">
              We are very happy to have you. Please tell us your name to get
              started.
            </div>
            <div className="w-full max-w-[282px] h-14 py-3.5 bg-[#efebfc] rounded-xl justify-center items-center inline-flex mx-auto">
              {formData.fullName ? (
                <p className="text-indigo-600 text-xl font-bold leading-7 truncate">
                  {formData.fullName}
                </p>
              ) : (
                <div className="text-indigo-600 text-xl font-bold leading-7">
                  --
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CopyrightText />
    </div>
  );
};

export default UserPersonalDetail;

import React from "react";
import OnBoardingLogoHeader from "../../components/header/OnBoardingLogoHeader";
import BusinessInformationForm from "../../features/business/BusinessInformationForm";
import BusinessBenefits from "../../features/business/BusinessBenefits";
import Svg from "../../assets/svg";
import { useNavigate } from "react-router-dom";
import { PERSONAL_INFORMATION } from "../../router/routeNames";

const BusinessInformation = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(PERSONAL_INFORMATION, { replace: true });
  };
  return (
    <div className="flex-1 min-h-screen flex flex-col bg-white">
      <OnBoardingLogoHeader />
      <div
        className="w-full h-1.5 bg-indigo-700 mb-6"
        style={{ width: "75%" }}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-x-20 flex-1 px-6 lg:px-16">
        <div className="flex flex-col h-full">
          <div
            className="w-[80px] md:w-[100px] h-10 md:h-11 px-2 py-1 md:px-2.5 md:py-1.5 rounded-lg border border-gray-200 flex justify-center items-center gap-2 cursor-pointer mb-4"
            onClick={handleBackClick}
          >
            <div className="flex items-center gap-2">
              <img
                src={Svg.back}
                alt="Back Icon"
                className="object-contain h-5 w-5 md:h-6 md:w-6"
              />
              <span className="text-indigo-500 text-sm font-bold">Back</span>
            </div>
          </div>

          <div className="w-full flex justify-start">
            <div className="w-[436px]">
              <BusinessInformationForm />
            </div>
          </div>
        </div>

        <div className="hidden lg:flex items-center justify-center">
          <BusinessBenefits />
        </div>
      </div>
      <div className="w-full h-24 flex items-end justify-center mt-6 mb-4 text-center">
        <div className="text-gray-500 text-xs font-medium">
          © 2024 JLRecharge, LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;

import React from "react";
import EmailResentPasswordSent from "../../features/authentication/EmailResentPasswordSent";
import OnBoardingLogoHeader from "../../components/header/OnBoardingLogoHeader";
const ForgotPasswordOpenEmail = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
      <EmailResentPasswordSent />
    </div>
  );
};

export default ForgotPasswordOpenEmail;

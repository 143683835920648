import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  AddMoney,
  Business,
  BusinessAdd,
  BusinessAddAgent,
  BusinessDetails,
  Dashboard,
  EmailVerification,
  EmailVerified,
  Payments,
  Profile,
  Recharge,
  Splash,
  TransactionsDetails,
  Transitions,
  WalletAddPaymentMethod,
  WalletPaymentMethodDelete,
  Wallets,
  UserPersonalInformation,
  BusinessInformation,
  WalletSetup,
  WalletSetupSuccessfully
} from "../pages/Index";

import {
  DASHBOARD,
  RECHARGE,
  TRANSACTIONS,
  PROFILE,
  BUSINESS,
  WALLET,
  WALLET_ADD_PAYMENTMETHOD,
  EMAIL_VERIFICATION,
  WALLET_RECHARGE,
  BUSINESS_DETAILS,
  SPLASH,
  BUSINESS_ADD,
  TRANSACTION_DETAILS,
  PAYMENTS,
  EMAIL_VERIFIED,
  PERSONAL_INFORMATION,
  BUSINESS_INFORMATION,
  WALLET_SETUP,
  WALLET_SETUP_PAYMENT_METHOD,
  WALLET_SETUP_SUCCESSFULLY
} from "./routeNames";
import WalletSetupPaymentMethod from "../pages/wallet/WalletSetupPaymentMethod";

const AuthenticatedRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Dashboard pageTitle="Dashboard" />}
        key="Dashboard"
      />
      <Route
        path={SPLASH}
        element={<Splash pageTitle="Splash" />}
        key="Splash"
      />
      <Route
        path={DASHBOARD}
        element={<Dashboard pageTitle="Dashboard" />}
        key="Dashboard"
      />
      <Route
        path={PERSONAL_INFORMATION}
        element={<UserPersonalInformation pageTitle="Personal Information" />}
        key="PersonalInformation"
      />
      <Route
        path={RECHARGE}
        element={<Recharge pageTitle="Recharge" />}
        key="Recharge"
      />
      <Route
        path={BUSINESS_INFORMATION}
        element={<BusinessInformation pageTitle="Business Information" />}
        key="Business Information"
      />
      <Route
        path={TRANSACTIONS}
        element={<Transitions pageTitle="Transactions" />}
        key="Transactions"
      />
      <Route
        path={PAYMENTS}
        element={<Payments pageTitle="Payments" />}
        key="Payments"
      />
      <Route
        path={WALLET_SETUP_PAYMENT_METHOD}
        element={<WalletSetupPaymentMethod pageTitle="Wallet Setup Payment Method" />}
        key="Wallet Setup Payment Method"
      />
      <Route
        path={`${TRANSACTION_DETAILS}/:transUid`}
        element={<TransactionsDetails pageTitle="Transaction Details" />}
        key="Transactions"
      />
      <Route path={PROFILE} element={<Profile />} key="Profile" />
      <Route
        path={BUSINESS}
        element={<Business pageTitle="Business" />}
        key="Business"
      />
      <Route
        path={`${BUSINESS_DETAILS}/:businessId`}
        element={<BusinessDetails pageTitle="Business Details" />}
        key="Business Details"
      />
      <Route
        path={BUSINESS_ADD}
        element={<BusinessAdd pageTitle="Add Business" />}
        key="AddBusiness"
      />
      <Route
        path="/business/:businessId/agent"
        element={<BusinessAddAgent pageTitle="Add Agent" />}
        key="Add Agent"
      />
      <Route
        path={WALLET}
        element={<Wallets pageTitle="Wallets" />}
        key="Wallets"
      />
      <Route
        path={WALLET_SETUP}
        element={<WalletSetup pageTitle="Wallet Setup" />}
        key="Wallet Setup"
      />
      <Route
        path={WALLET_SETUP_SUCCESSFULLY}
        element={<WalletSetupSuccessfully pageTitle="Wallet Setup Successfully" />}
        key="Wallet Setup Successfully"
      />
      <Route
        path={WALLET_ADD_PAYMENTMETHOD}
        element={<WalletAddPaymentMethod pageTitle="Add Payment Method" />}
        key="Add Payment Method"
      />
      <Route
        path="/wallet/payment-method/:paymentMethodId/delete"
        element={
          <WalletPaymentMethodDelete pageTitle="Payment Method Delete" />
        }
        key="Payment Method Delete"
      />
      <Route
        path={WALLET_RECHARGE}
        element={<AddMoney pageTitle="Wallets" />}
        key="Wallets"
      />
      <Route
        path={EMAIL_VERIFICATION}
        element={<EmailVerification />}
        key="EmailVerification"
      />
      <Route path={`${EMAIL_VERIFIED}/:userID`} element={<EmailVerified />} />
    </Routes>
  );
};

export default AuthenticatedRouter;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirestoreBusiness from "../../api/firebase/firestore.business";
import FirestoreUser from "../../api/firebase/firestore.user";
import FirestoreWallets from "../../api/firebase/firestore.wallets";
import { ROLES } from "../../features/authentication/models";
import {
  businessAddMany,
  businessAddOne,
  businessSelected,
  businessStatus,
  selectBusinessSelected,
} from "../../features/business/businessSlice";
import { BUSINESS_READY } from "../../features/business/businessStatus";
import { userAdd, userStatus } from "../../features/user/userSlice";
import { USER_READY } from "../../features/user/userStatus";
import { walletAdd, walletsStatus } from "../../features/wallets/walletSlice";
import { WALLETS_READY } from "../../features/wallets/walletStatus";
import {
  SPLASH,
  PERSONAL_INFORMATION,
  BUSINESS,
  BUSINESS_INFORMATION,
  WALLET_SETUP,
} from "../../router/routeNames";

const withBoostraApp = (Component) => () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const authentication = useSelector((state) => state.authentication);
  const business = useSelector((state) => selectBusinessSelected(state));

  useEffect(() => {
    async function boostrappApp() {
      const userData =
        user === undefined || user?.data === undefined
          ? await FirestoreUser.LoadById(authentication?.data?.uid)
          : user?.data;
      if (userData === undefined || userData === null) {
        navigate(SPLASH, { replace: true });
        return;
      }

      dispatch(userAdd(userData));
      dispatch(userStatus(USER_READY));

      // Navigate to User Personal Information
      if (!userData.isActive) {
        navigate(PERSONAL_INFORMATION, { replace: true });
        return;
      }

      let businessActive = null;
      const isAgentOrEmployee =
        userData.roles[ROLES.agent] || userData.roles[ROLES.employee];
      if (business === undefined) {
        const businessData = isAgentOrEmployee
          ? await FirestoreBusiness.LoadById(userData.businessId)
          : await FirestoreBusiness.LoadByOwnerId(userData.uid);
        if (isAgentOrEmployee && businessData !== null) {
          dispatch(businessAddOne(businessData));
          dispatch(businessSelected(businessData));
          dispatch(businessStatus(BUSINESS_READY));
          businessActive = businessData;
        } else if (businessData !== null && businessData.length > 0) {
          dispatch(businessAddMany(businessData));
          dispatch(businessStatus(BUSINESS_READY));
          businessActive = businessData[0];

          businessData.filter((b) => {
            if (b.selected) {
              businessActive = b;
            }
          });
          dispatch(businessSelected(businessActive));
        }
      } else {
        businessActive = business;
      }
      // Business still in review process
      if (!businessActive) {
        navigate(BUSINESS_INFORMATION, { replace: true });
        return;
      }
      const wallet = isAgentOrEmployee
        ? await FirestoreWallets.GetById(businessActive.ownerId)
        : await FirestoreWallets.GetById(userData.uid, businessActive.uid);

      if (wallet !== undefined) {
        dispatch(walletAdd(wallet));
        dispatch(walletsStatus(WALLETS_READY));
      }

      // Wallet setup
      if (!userData.isWalletSetup) {
        navigate(WALLET_SETUP, { replace: true });
        return;
      }

      // Business no approved - Review
      if (!businessActive.isApproved) {
        navigate(BUSINESS);
        return;
      }
    }

    boostrappApp();
  }, [user?.data, business]);
  return <Component />;
};

export default withBoostraApp;

import React from "react";
import SendEmailResetPassword from "../../features/authentication/SendEmailResetPassword";
import OnBoardingLogoHeader from "../../components/header/OnBoardingLogoHeader";

const ForgotPassword = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
      <SendEmailResetPassword />
    </div>
  );
};
export default ForgotPassword;
